:root {
    // Colors

    // Gray
    --gray-1: #f5f6fa;
    --gray-2: #ebecf4;
    --gray-3: #adb4c0;
    --gray-4: #8c8c8c;
    --gray-5: #68707e;
    --gray-6: #d9d9d9;
    --gray-7: #f5f5f5;
    --gray-8: #666;
    --gray-9: #c2d2e4;
    --gray-10: #979da8;
    --gray-11: #f0f0f0;
    --gray-12: #acacac;
    --gray-13: #acb4c0;

    // White
    --white-primary: #fff;
    --white-1: #ddd;
    --white-2: #f8f8f8;

    // Black
    --black-primary: #262f41;
    --black-1: #001529;
    --black-2: #000;
    --black-3: #232323;
    --black-4: #000c17;
    --black-5: #1d1d1d;

    // Red
    --red-primary: #ff4d4f;
    --red-1: #fff1f0;
    --red-2: #ff7875;
    --red-3: #ffccc7;

    // Yellow
    --yellow-primary: #faad14;
    --yellow-1: #ffc53d;
    --yellow-2: #fff1b8;
    --yellow-3: #ffd666;
    --yellow-4: #fffbe6;
    --yellow-5: #ad6800;

    // Violet
    --violet-primary: #722ed1;
    --violet-1: #391085;

    // Blue
    --blue-primary: #1677ff;
    --blue-1: #2c426d;
    --blue-2: #4096ff;
    --blue-3: #0958d9;
    --blue-4: #43516b;
    --blue-5: #91caff;
    --blue-6: #2064e4;
    --blue-7: #2e92ff;
    --blue-8: #e6f4ff;

    // Green
    --green-primary: #52c41a;
    --green-1: #f6ffed;
    --green-2: #d9f7be;
    --green-3: #95de64;

    // Black percent
    --black-percent-02: rgb(0 0 0 / 2%);
    --black-percent-03: rgb(0 0 0 / 3%);
    --black-percent-04: rgb(0 0 0 / 4%);
    --black-percent-05: rgb(0 0 0 / 5%);
    --black-percent-06: rgb(0 0 0 / 6%);
    --black-percent-07: rgb(0 0 0 / 7%);
    --black-percent-08: rgb(0 0 0 / 8%);
    --black-percent-09: rgb(0 0 0 / 9%);
    --black-percent-10: rgb(0 0 0 / 10%);
    --black-percent-12: rgb(0 0 0 / 12%);
    --black-percent-15: rgb(0 0 0 / 15%);
    --black-percent-16: rgb(0 0 0 / 16%);
    --black-percent-20: rgb(0 0 0 / 20%);
    --black-percent-25: rgb(0 0 0 / 25%);
    --black-percent-30: rgb(0 0 0 / 30%);
    --black-percent-45: rgb(0 0 0 / 45%);
    --black-percent-55: rgb(0 0 0 / 55%);
    --black-percent-65: rgb(0 0 0 / 65%);
    --black-percent-85: rgb(0 0 0 / 85%);
    --black-percent-88: rgb(0 0 0 / 88%);
    --black-percent-90: rgb(0 0 0 / 90%);

    // White percent
    --white-percent-0: rgb(255 255 255 / 0%);
    --white-percent-16: rgb(255 255 255 / 16%);
    --white-percent-25: rgb(255 255 255 / 25%);
    --white-percent-65: rgb(255 255 255 / 65%);
    --white-percent-85: rgb(255 255 255 / 65%);

    // Gray percent
    --gray-percent-08: rgb(170 170 170 / 8%);
}

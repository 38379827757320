@use 'app/styles/mixins/mixins';

.mediaWrapper .ant-upload, .mediaWrapper .ant-upload-list-item  {
    max-width: 155px !important;
    width: 100% !important;
    height: 120px !important;

    @include mixins.media-screen(tablet) {
        max-width: 178px !important;
        height: 130px !important;
    }
    @include mixins.media-screen(mobile) {
        max-width: 214px !important;
        height: 160px !important;
    }
    @include mixins.media-screen(mobile-small) {
        max-width: 170px !important;
        height: 127px !important;
    }
}

.mediaWrapper .ant-upload-list-item {
    padding: 0 !important;
    border-radius: var(--radius-2) !important;

    img {
        object-fit: cover !important;
        border-radius: var(--radius-2) !important;
    }
}

.mediaWrapper .ant-upload-list-item-container{
    max-width: 155px !important;
    width: 100% !important;
    height: 120px !important;

    @include mixins.media-screen(tablet) {
        max-width: 178px !important;
        height: 130px !important;
    }
    @include mixins.media-screen(mobile) {
        max-width: 214px !important;
        height: 160px !important;
    }
    @include mixins.media-screen(mobile-small) {
        max-width: 170px !important;
        height: 127px !important;
    }
}

.ant-modal-content {
    height: 100%;
}
/* stylelint-disable property-no-vendor-prefix */
@mixin fade-in-scroll {
    overflow-y: auto;
    mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 5px, black 9px);
    mask-size: 100% 20000px;
    mask-position: left bottom;
    -webkit-mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 8px, black 9px);
    -webkit-mask-size: 100% 20000px;
    -webkit-mask-position: left bottom;
    transition: mask-position 0.3s, -webkit-mask-position 0.3s;

    &:hover {
        -webkit-mask-position: left top;
    }
}

@mixin media-screen($breakpoint) {
    @if $breakpoint == mobile-small {
        @media screen and (max-width: 479px) {
            @content;
        }
    } @else if $breakpoint == mobile {
        @media screen and (max-width: 767px) {
            @content;
        }
    } @else if $breakpoint == tablet {
        @media screen and (max-width: 1023px) {
            @content;
        }
    } @else if $breakpoint == laptop {
        @media screen and (max-width: 1439px) {
            @content;
        }
    } @else if $breakpoint == desktop {
        @media screen and (min-width: 1440px) {
            @content;
        }
    }
}

@mixin media-screen-between($breakpoint) {
    @if $breakpoint == mobile-small {
        @media screen and (max-width: 479px) and (min-width: 320px) {
            @content;
        }
    } @else if $breakpoint == mobile {
        @media screen and (max-width: 767px) and (min-width: 480px) {
            @content;
        }
    } @else if $breakpoint == tablet {
        @media screen and (max-width: 1023px) and (min-width: 768px) {
            @content;
        }
    } @else if $breakpoint == laptop {
        @media screen and (max-width: 1439px) and (min-width: 1024px) {
            @content;
        }
    } @else if $breakpoint == desktop {
        @media screen and (min-width: 1440px) {
            @content;
        }
    }
}
